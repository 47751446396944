// extracted by mini-css-extract-plugin
export const partnersContainer = "reviews-module--partnersContainer--Ipmb9";
export const achievementsContainer = "reviews-module--achievementsContainer--3nb3C";
export const achievementsGrid = "reviews-module--achievementsGrid--1qHRv";
export const reviewsGrid = "reviews-module--reviewsGrid--GNYaY";
export const reviewCard = "reviews-module--reviewCard--vahIr";
export const reviewsContainer = "reviews-module--reviewsContainer--24XtR";
export const reviewsTopContainer = "reviews-module--reviewsTopContainer--2S0DZ";
export const reviewImg = "reviews-module--reviewImg--1ujCw";
export const reviewSvg = "reviews-module--reviewSvg--1E7wv";
export const reviewsNameContainer = "reviews-module--reviewsNameContainer--20_Pd";
export const reviewName = "reviews-module--reviewName--3lAXt";
export const reviewStar = "reviews-module--reviewStar--1ZA5d";
export const reviewDate = "reviews-module--reviewDate--2VH3K";
export const reviewsLinks = "reviews-module--reviewsLinks--2oQTY";
export const socialGrid = "reviews-module--socialGrid--2LV59";
export const socialContainer = "reviews-module--socialContainer--3g3cY";
export const socialTitle = "reviews-module--socialTitle--1UWsM";
export const socialText = "reviews-module--socialText--1QARB";
export const reviewText = "reviews-module--reviewText--1PDVE";